
import { message } from 'ant-design-vue';
import { defineComponent, ref } from 'vue';
import { openInNewWindow } from '@/utils/illuminate';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';
import type { TableColumn } from '@/components/Table';
import { useUtils } from '@/core/utils';
import { formatDate } from '@/core/filters';
import { useState } from '@/store';
import DetailModal from './Detail.vue';

export default defineComponent({
  name: 'ReasonListView',
  components: {
    DetailModal,
  },
  setup(props, ctx) {
    const state = useState();
    const utils = useUtils();

    const baseUrl = createApiUrl('/newlinkSass/gift/card/download-zip');

    // 加载数据方法 必须为 Promise 对象
    const loadTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      request.get(createApiUrl('/newlinkSass/gift/card/batch-page-info'), { params: parameter })
        .then((res: any) => {
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const tableRef = ref<any>(null as any);

    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    const onRecycle = async (record: any) => {
      try {
        await request.put(createApiUrl('/newlinkSass/gift/card/recycle-batch-number'), {
          batchNumber: record.batchNumber,
        });
      } catch (e: Error & any) {
        return message.error('加载企业账户信息失败!');
      }

      message.success('礼品卡批次回收完成');
      onRefreshTable();
    };


    const onDownload = async (record: any, type = 'zip') => {
      let res: any;
      const path = type === 'zip' ? '/gift/card/download-zip' : '/excel/excel-gift-card';
      try {
        res = await request.get(createApiUrl(`/newlinkSass${path}`), { params: { batchNumber: record.batchNumber } });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      openInNewWindow(res.data);
    };

    const tableColumns: TableColumn[] = [
      { title: '批次', dataIndex: 'batchNumber' },
      { title: '开卡金额', dataIndex: 'amount' },
      { title: '开卡数量', dataIndex: 'quantity' },
      {
        title: '状态',
        dataIndex: 'status',
        customRender: utils.createTagSlot({
          1: { color: 'default', text: '生成中' },
          2: { color: 'green', text: '生成完成' },
          3: { color: 'red', text: '生成失败' },
        }),
      },
      { title: '开卡时间', dataIndex: 'issueTime', customRender: ({ text }) => formatDate(text) },
      { title: '操作', dataIndex: 'action', width: '300px', slots: { customRender: 'action' }, fixed: 'right' },
    ];

    return {
      baseUrl,

      tableColumns,
      tableRef,
      loadTableData,
      onRefreshTable,
      onRecycle,
      onDownload,
    };
  },
});
