import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "giftCardEditorView__content" }
const _hoisted_2 = { class: "giftCardEditorView__formBlock" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, {
    class: "utils__pageContainer giftCardEditorView__wrap",
    title: "开礼品卡"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_form, {
          ref: "editorFormRef",
          class: "giftCardEditorView__form",
          model: _ctx.formData,
          rules: _ctx.rules,
          "label-col": _ctx.labelCol,
          "wrapper-col": _ctx.wrapperCol
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_form_item, { label: "企业可用余额" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    class: "amount font-DIN",
                    innerHTML: _ctx.renderWithSub(_ctx.formatWithIntl(_ctx.financeDetail.availableAmount))
                  }, null, 8, _hoisted_3),
                  _createTextVNode(" 元 ")
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "开卡金额",
                required: "",
                name: "amount"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input_number, {
                    value: _ctx.formData.amount,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.amount) = $event)),
                    min: 0,
                    step: 0.01,
                    placeholder: "请输入开卡金额",
                    suffix: "元",
                    style: {"width":"100%"}
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "礼品卡数量",
                required: "",
                name: "quantity"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input_number, {
                    value: _ctx.formData.quantity,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.quantity) = $event)),
                    min: 0,
                    step: 1,
                    placeholder: "请输入开卡数量",
                    suffix: "元",
                    style: {"width":"100%"}
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                required: "",
                "wrapper-col": _ctx.buttonCol
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" 共计: "),
                  _createElementVNode("span", {
                    class: _normalizeClass({ 'text-red': _ctx.biggerThenAmount }),
                    innerHTML: `${_ctx.renderWithSub(_ctx.formatWithIntl(_ctx.totalAmount))}  元 ${ _ctx.biggerThenAmount ? '已超过企业可用余额' : '' }`
                  }, null, 10, _hoisted_4)
                ]),
                _: 1
              }, 8, ["wrapper-col"]),
              _createVNode(_component_a_form_item, {
                required: "",
                "wrapper-col": _ctx.buttonCol
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    disabled: _ctx.submiting,
                    loading: _ctx.submiting,
                    onClick: _ctx.onSubmit
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("提交")
                    ]),
                    _: 1
                  }, 8, ["disabled", "loading", "onClick"])
                ]),
                _: 1
              }, 8, ["wrapper-col"])
            ])
          ]),
          _: 1
        }, 8, ["model", "rules", "label-col", "wrapper-col"])
      ])
    ]),
    _: 1
  }))
}