
import { defineComponent, reactive, ref, computed } from 'vue';
import { Form } from 'ant-design-vue';

export default defineComponent<{}, {}, {
  visible: boolean;
  record: any;
}, {}, any>({
  name: 'AutoDetailModal',
  data() {
    return {
      visible: false,
      record: {},

      labelCol: { span: 10 },
      wrapperCol: { span: 12, offset: 1 },
    };
  },
  computed: {
    form(): (typeof Form) {
      return this.$refs.editorForm as (typeof Form);
    },
  },
  methods: {
    view(record: any) {
      // this.record = record;
      this.visible = true;
    },
    resetFields() {
      this.form.resetFields();
    },
  },
});
