
import { message } from 'ant-design-vue';
import { computed, defineComponent, reactive, ref } from 'vue';
import { Moment } from 'moment';
import { openInNewWindow, noEmptyProp, not } from '@/utils/illuminate';

import { createApiUrl } from '@/utils/utils';
import type { TableColumn, TableOptions } from '@/components/Table';
import { request } from '@/utils/request';
import { useUtils } from '@/core/utils';

import DetailModal from './Detail.vue';

export default defineComponent({
  name: 'ReasonListView',
  components: {
    DetailModal,
  },
  setup(props, ctx) {
    const utils = useUtils();
    const filterParams = reactive<{
      cardNo: string;
      batchNumber: string;
      cardStatus: string;
      issueTimeRange: Moment[];
    }>({
      cardNo: '',
      batchNumber: '',
      cardStatus: '',
      issueTimeRange: [],
    });
    const tableSelectedRowKeys = ref<any[]>([]);
    const tableSelectedRows = ref<any[]>([]);

    const onTableSelectChange = (selectedRowKeys: any[], selectedRows: any[]) => {
      tableSelectedRowKeys.value = selectedRowKeys;
      tableSelectedRows.value = selectedRows;
    };

    const tableOptions = computed<TableOptions>(() => ({
      alert: { show: true, clear: () => { tableSelectedRowKeys.value = []; } },
      rowSelection: {
        selectedRowKeys: tableSelectedRowKeys.value,
        onChange: onTableSelectChange,
        getCheckboxProps: (record: any) => ({
          props: { name: 'check-line' },
        }),
      },
    }));

    // 加载数据方法 必须为 Promise 对象
    const loadTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      const params: Record<string, any> = { ...parameter, ...noEmptyProp(not(filterParams, ['issueTimeRange'])) };
      if (filterParams.issueTimeRange.length) {
        params.startTime = filterParams.issueTimeRange[0].toString();
        params.endTime = filterParams.issueTimeRange[1].toString();
      }

      request.get(createApiUrl('/newlinkSass/gift/card/page-info'), { params })
        .then((res: any) => {
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const tableRef = ref<any>(null as any);

    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    const onRecycle = async (record: any) => {
      try {
        await request.put(createApiUrl('/newlinkSass/gift/card/recycle-card-no'), {
          cardNo: record.cardNo,
        });
      } catch (e: Error & any) {
        return message.error('礼品卡回收失败');
      }

      message.success('礼品卡回收完成');
      onRefreshTable();
    };

    const onViewItem = async (record: any) => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/gift/card/download'), {
          params: { cardNo: record.cardNo },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      openInNewWindow(res.data);
    };

    const tableColumns: TableColumn[] = [
      { title: '批次', dataIndex: 'batchNumber' },
      { title: '油卡编号', dataIndex: 'cardNo' },
      { title: '开卡金额', dataIndex: 'amount' },
      { title: '开卡时间', dataIndex: 'issueTime' },
      {
        title: '油卡状态',
        dataIndex: 'cardStatus',
        customRender: utils.createTagSlot({
          1: { color: 'default', text: '未送出' },
          2: { color: 'green', text: '已送出' },
          3: { color: 'green', text: '已领取' },
          4: { color: 'default', text: '已作废' },
        }),
      },
      { title: '二维码', dataIndex: 'canPay', slots: { customRender: 'view-qrcode' } },
      { title: '操作', dataIndex: 'action', width: '140px', slots: { customRender: 'action' }, fixed: 'right' },
    ];

    return {
      filterParams,
      tableSelectedRowKeys,
      tableSelectedRows,
      tableOptions,
      tableColumns,
      tableRef,

      onRefreshTable,
      loadTableData,
      onRecycle,
      onViewItem,
    };
  },
});
