import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    class: "autoDetailModal",
    title: "车辆详情",
    width: "600px",
    footer: null
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        class: "autoDetailModal__form",
        "label-col": _ctx.labelCol,
        "wrapper-col": _ctx.wrapperCol
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, { label: "车牌号" }, {
            default: _withCtx(() => [
              _createTextVNode(" 懒ABC123 ")
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "状态" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_tag, { color: "blue" }, {
                default: _withCtx(() => [
                  _createTextVNode("启用")
                ]),
                _: 1
              }),
              _createVNode(_component_a_tag, { color: "red" }, {
                default: _withCtx(() => [
                  _createTextVNode("禁用")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "车辆额度" }, {
            default: _withCtx(() => [
              _createTextVNode(" 每月 - 20000 ¥ ")
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "是否开启车辆验证" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_tag, { color: "blue" }, {
                default: _withCtx(() => [
                  _createTextVNode("开启")
                ]),
                _: 1
              }),
              _createVNode(_component_a_tag, { color: "red" }, {
                default: _withCtx(() => [
                  _createTextVNode("关闭")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["label-col", "wrapper-col"])
    ]),
    _: 1
  }, 8, ["visible"]))
}