
import { defineComponent, computed, onMounted, reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import { parseNumberOrZero } from '@/utils/illuminate';

import { createApiUrl, firstError, isValidateError } from '@/utils/utils';
import { request } from '@/utils/request';
import router from '@/router';

export default defineComponent({
  name: 'AutoEditor',
  components: {},
  setup(prop, ctx) {
    const submiting = ref(false);

    const financeDetail = reactive({
      availableAmount: 0,
      frozenAmount: 0,
      refundAmount: 0,
      totalBalance: 0,
      totalDistributionBalance: 0,
    });

    const formData = reactive({
      cardType: 1,
      amount: '',
      quantity: '',
    });


    const rules = {
      amount: [
        { required: true, type: 'number', message: '请输入开卡金额', trigger: 'blur' },
        {
          validator: (rule: any, value: any) => (+value === 0 ? Promise.reject('开卡金额不得为 0') : Promise.resolve()),
          trigger: 'blur',
        },
      ],
      quantity: [
        { required: true, type: 'number', message: '请输入开卡数量', trigger: 'blur' },
        {
          validator: (rule: any, value: any) => (+value === 0 ? Promise.reject('开卡数量不得为 0') : Promise.resolve()),
          trigger: 'blur',
        },
      ],
    };

    const totalAmount = computed(() => parseNumberOrZero(formData.amount) * parseNumberOrZero(formData.quantity));

    const biggerThenAmount = computed(() => totalAmount.value > financeDetail.availableAmount);

    const loadBlance = async () => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/finance/find-finance-detail'));
      } catch (e: Error & any) {
        return message.error('加载企业账户信息失败!');
      }

      Object.assign(financeDetail, res.data);
    };

    const editorFormRef = ref<any>(null as any);
    const onSubmit = () => {
      editorFormRef.value.validate()
        .then(() => {
          submiting.value = true;
          if (biggerThenAmount.value) return Promise.reject(new Error('开卡金额超过企业可用余额'));

          return request.put(createApiUrl('/newlinkSass/gift/card/issue'), formData);
        })
        .then(() => {
          message.success('礼品卡批次创建完成');
          router.push({ name: 'gift_card.batch' });
        })
        .catch((e: any) => {
          message.error(isValidateError(e) ? firstError(e) : e.message);
        })
        .finally(() => {
          submiting.value = false;
        });
    };

    onMounted(() => {
      loadBlance();
    });

    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 16, offset: 1 },
      buttonCol: { span: 16, offset: 5 },
      rules,

      submiting,
      totalAmount,
      biggerThenAmount,

      editorFormRef,
      financeDetail,
      formData,
      onSubmit,
    };
  },
});
